import { Inject, Injectable } from '@angular/core';
import { GRAPHQL_CLIENT_LOADER, GraphQlClientLoader, parseGraphQlErrors } from '@origin8-web/core-ui/graphql';
import {
  AdvocatePaidTime,
  CallLog,
  FilterComparators,
  O8GqlResult,
  O8RootQueryFilter,
  RepresentativeProfile,
  UserAppointment,
} from 'common.interfaces';
import { Observable, map, catchError } from 'rxjs';
import {
  getPaidTimeAndCallLogsQuery,
  getReprensativeInfoQuery,
  getScheduledTimeQuery,
  getUserAppointmentsQuery,
} from './gql/advocate.query';

@Injectable({
  providedIn: 'root',
})
export class AdvocateApi {
  constructor(@Inject(GRAPHQL_CLIENT_LOADER) private graphqlClientLoader: GraphQlClientLoader) {}

  getRepresentativeProfiles(filters: O8RootQueryFilter<RepresentativeProfile>): Observable<RepresentativeProfile[]> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ userProfileResults: O8GqlResult<RepresentativeProfile> }>({
        query: getReprensativeInfoQuery,
        variables: {
          filters,
        },
      })
      .pipe(
        map((r) => r.data.userProfileResults.results ?? []),
        catchError(parseGraphQlErrors),
      );
  }

  getUserScheduledTimeForWeek(email: string, weekNumber: number): Observable<Date[]> {
    const filters = {
      and: [
        {
          key: 'email',
          comparator: FilterComparators.EQUAL,
          value: email,
        },
        {
          key: 'weekNumber',
          comparator: FilterComparators.EQUAL,
          value: weekNumber,
        },
      ],
    };

    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ advocatePaidTimesResultsV2: O8GqlResult<AdvocatePaidTime> }>({
        query: getScheduledTimeQuery,
        variables: {
          filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((r) =>
          (r.data.advocatePaidTimesResultsV2.results ?? []).flatMap((t) => t.scheduledSlots.map((s) => s.slots)),
        ),
        catchError(parseGraphQlErrors),
      );
  }

  getAdvocatePaidTimes(filters: O8RootQueryFilter<AdvocatePaidTime>): Observable<AdvocatePaidTime[]> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ advocatePaidTimesResultsV2: O8GqlResult<AdvocatePaidTime> }>({
        query: getScheduledTimeQuery,
        variables: {
          filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((r) => r.data.advocatePaidTimesResultsV2.results ?? []),
        catchError(parseGraphQlErrors),
      );
  }

  getAdvocatePaidTimesAndCallLogs(
    paidTimeFilters: O8RootQueryFilter<AdvocatePaidTime>,
    callLogFilters: O8RootQueryFilter<CallLog>,
  ): Observable<{ paidTimes: AdvocatePaidTime[]; callLogs: CallLog[] }> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ advocatePaidTimesResultsV2: O8GqlResult<AdvocatePaidTime>; callLogResults: O8GqlResult<CallLog> }>({
        query: getPaidTimeAndCallLogsQuery,
        variables: {
          paidTimeFilters,
          callLogFilters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((r) => {
          return {
            paidTimes: r.data.advocatePaidTimesResultsV2.results ?? [],
            callLogs: r.data.callLogResults.results ?? [],
          };
        }),
        catchError(parseGraphQlErrors),
      );
  }

  getUserAppointments(
    filters: O8RootQueryFilter<UserAppointment>,
  ): Observable<Array<UserAppointment & { startDateTime: Date; endDateTime: Date }>> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{
        AdvocateAppointmentsResults: { results: Array<UserAppointment & { startDateTime: Date; endDateTime: Date }> };
      }>({
        query: getUserAppointmentsQuery,
        variables: {
          filters,
        },
      })
      .pipe(
        map((res) => {
          return res.data.AdvocateAppointmentsResults.results ?? [];
        }),
        catchError(parseGraphQlErrors),
      );
  }
}
