import { inject, Injectable } from '@angular/core';
import {
  AdvocatePaidTime,
  CallLog,
  FilterComparators,
  O8RootQueryFilter,
  RepresentativeProfile,
  SF_ADVOCATE_ROLE_ID,
  UserAppointment,
} from 'common.interfaces';
import { map, Observable } from 'rxjs';
import { AdvocateApi } from './advocate.api';
import { DateTime } from 'luxon';
import { sortAscBy, withoutTrailingSlash } from '@origin8-web/o8-utils/format';
import { O8ConfigService } from '@origin8-web/core-ui/config';
import { Protec8Env } from '../../environments';
import { BrowserExtApiService } from '@origin8-web/core-ui/browser-ext-api';
import { getAssociatedCallLogsWithAdvocatePaidTime, getDayFiltersForWholeWeek } from './advocate.service-gateway.utils';
import { AdvocateBasePaidTimeInfoWithCallLogs } from '../../app.interface';

@Injectable({
  providedIn: 'root',
})
export class AdvocateServiceGateway {
  private readonly advocateApi = inject(AdvocateApi);
  private readonly configService = inject<O8ConfigService<Protec8Env>>(O8ConfigService);
  private readonly browserExtApiService = inject(BrowserExtApiService);

  getUserScheduledTimeForNextWeek(email: string): Observable<Date[]> {
    const weekNumber = DateTime.now().weekNumber + 1;
    return this.advocateApi.getUserScheduledTimeForWeek(email, weekNumber);
  }

  getAllRepresentativeProfiles(): Observable<RepresentativeProfile[]> {
    const filters: O8RootQueryFilter<RepresentativeProfile> = {
      and: [
        {
          key: 'isActive',
          comparator: FilterComparators.EQUAL,
          value: true,
        },
        {
          key: 'sfUserRoleId',
          comparator: FilterComparators.EQUAL,
          value: SF_ADVOCATE_ROLE_ID,
        },
      ],
    };
    return this.advocateApi.getRepresentativeProfiles(filters).pipe(
      map((representativeProfiles: RepresentativeProfile[]) => {
        return representativeProfiles;
      }),
    );
  }

  getAllAdvocatesScheduledTimesForToday(): Observable<Array<Pick<AdvocatePaidTime, 'email' | 'scheduledSlots'>>> {
    const startOfToday = DateTime.now().startOf('day').toISO() as string;
    const filters: O8RootQueryFilter<AdvocatePaidTime> = {
      and: [
        {
          key: 'startWorkingDay',
          comparator: FilterComparators.EQUAL,
          value: startOfToday,
        },
      ],
    };
    return this.advocateApi.getAdvocatePaidTimes(filters).pipe(
      map((advocatePaidTimes: AdvocatePaidTime[]) => {
        return advocatePaidTimes.map((advocatePaidTime) => {
          return {
            email: advocatePaidTime.email,
            scheduledSlots: advocatePaidTime.scheduledSlots,
          };
        });
      }),
    );
  }

  getAllAdvocatesPaidTimeInfoForWeek(filters: {
    startDate: string;
    endDate: string;
    emails: string[];
  }): Observable<Array<AdvocateBasePaidTimeInfoWithCallLogs>> {
    const paidTimeFilters: O8RootQueryFilter<AdvocatePaidTime> = {
      and: [
        {
          key: 'email',
          comparator: FilterComparators.IN,
          value: filters.emails,
        },
      ],
      or: getDayFiltersForWholeWeek(filters.startDate),
    };

    const callLogFilters: O8RootQueryFilter<CallLog> = {
      and: [
        {
          key: 'startTime',
          comparator: FilterComparators.GREATER,
          value: filters.startDate,
        },
        {
          key: 'startTime',
          comparator: FilterComparators.LOWER,
          value: filters.endDate,
        },
        {
          key: 'advocateEmail',
          comparator: FilterComparators.IN,
          value: filters.emails,
        },
      ],
    };
    return this.advocateApi.getAdvocatePaidTimesAndCallLogs(paidTimeFilters, callLogFilters).pipe(
      map((advocatesPaidTimesAndCallLogs) => {
        return advocatesPaidTimesAndCallLogs.paidTimes.map((advocatePaidTime) => {
          return {
            email: advocatePaidTime.email,
            startWorkingDay: advocatePaidTime.startWorkingDay,
            scheduledSlots: advocatePaidTime.scheduledSlots,
            workedTime: advocatePaidTime.workedTime,
            userInfo: advocatePaidTime.userInfo,
            callLogs: getAssociatedCallLogsWithAdvocatePaidTime(
              advocatePaidTime,
              advocatesPaidTimesAndCallLogs.callLogs,
            ),
            day: advocatePaidTime.day,
            month: advocatePaidTime.month,
            year: advocatePaidTime.year,
          } satisfies AdvocateBasePaidTimeInfoWithCallLogs;
        });
      }),
    );
  }

  getUserAppointmentsByOwnerIdForToday(userId: string): Observable<UserAppointment[]> {
    const now = DateTime.now().toISO() as string;
    const endOfToday = DateTime.now().endOf('day').toISO() as string;
    const filters: O8RootQueryFilter<UserAppointment> = {
      and: [
        {
          key: 'ownerId',
          comparator: FilterComparators.EQUAL,
          value: userId,
        },
        {
          key: 'startDateTime',
          comparator: FilterComparators.GREATER,
          value: now,
        },
        {
          key: 'startDateTime',
          comparator: FilterComparators.LOWER,
          value: endOfToday,
        },
      ],
    };
    return this.advocateApi.getUserAppointments(filters).pipe(
      map((userAppointments: Array<UserAppointment & { startDateTime: Date; endDateTime: Date }>) => {
        const upcomingUserAppointments: UserAppointment[] = userAppointments.map((appointment) => {
          return {
            ...appointment,
            startDateTime: DateTime.fromJSDate(appointment.startDateTime),
            endDateTime: DateTime.fromJSDate(appointment.endDateTime),
          };
        });
        return sortAscBy('startDateTime')(upcomingUserAppointments) as UserAppointment[];
      }),
    );
  }

  openAppointmentInSf = (appointment: UserAppointment): void => {
    const sfBaseUrl = withoutTrailingSlash(this.configService.get('salesForceBaseUrl'));
    this.browserExtApiService.triggerSfNavigation(appointment.externalIdentifier, sfBaseUrl, 'Event');
  };

  getSfLinkForAppointment = (appointment: UserAppointment): string => {
    const sfBaseUrl = withoutTrailingSlash(this.configService.get('salesForceBaseUrl'));
    return `${sfBaseUrl}/lightning/r/Event/${appointment.externalIdentifier}/view`;
  };
}
