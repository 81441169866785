import { AdvocatePaidTime, CallLog, FilterComparators, O8FilterQuery, O8RootQueryFilter } from 'common.interfaces';
import { DateTime } from 'luxon';

export const getAssociatedCallLogsWithAdvocatePaidTime = (
  paidTimeInfo: Pick<AdvocatePaidTime, 'email' | 'startWorkingDay' | 'endWorkingDay'>,
  callLogs: CallLog[],
): CallLog[] => {
  return callLogs.filter((callLog) => {
    if (!callLog.startTime && paidTimeInfo.startWorkingDay && paidTimeInfo.endWorkingDay) {
      return false;
    }
    // return true;
    return (
      callLog.advocateEmail === paidTimeInfo.email &&
      callLog.startTime.getTime() >= paidTimeInfo.startWorkingDay.getTime() &&
      callLog.startTime.getTime() <= paidTimeInfo.endWorkingDay.getTime()
    );
  });
};

export const getDayFiltersForWholeWeek = (startDate: string): O8RootQueryFilter<AdvocatePaidTime>[] => {
  const filters: O8RootQueryFilter<AdvocatePaidTime>[] = [];
  for (let i = 0; i < 7; i++) {
    filters.push({
      and: [
        {
          key: 'day',
          comparator: FilterComparators.EQUAL,
          value: DateTime.fromISO(startDate).plus({ day: i }).day,
        },
        {
          key: 'month',
          comparator: FilterComparators.EQUAL,
          value: DateTime.fromISO(startDate).month,
        },
        {
          key: 'year',
          comparator: FilterComparators.EQUAL,
          value: DateTime.fromISO(startDate).year,
        },
      ],
    } satisfies O8RootQueryFilter<AdvocatePaidTime>);
  }
  return filters;
};
